body {
  @apply text-slate-700 bg-slate-50;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold text-slate-800 mb-1;
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
}

h4 {
  @apply text-xl;
}

h5,
h6 {
  @apply text-lg;
}

p {
  @apply text-lg mb-6;
}

input,
select,
textarea {
  @apply w-full;
  @apply border border-slate-200;
  @apply rounded;
  @apply p-3;
}

input,
select {
  @apply h-12;
}
